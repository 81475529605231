import { React, useMemo } from "react";
import { useNavigate } from "react-router";
import ReactWordcloud from "react-wordcloud";
import "../css/Main.css";

function WordCloud() {
  const navigate = useNavigate();
  const callbacks = useMemo(() => {
    return {
      onWordClick: (key) => {
        // jmy add 1102 11:09
        // '#' 문자를 제거 하여 cleanedText 변수에 저장
        key.text = key.text.replace(/#/g, '');
        if(key.text === 'SKKU' || key.text === 'LearningFair' || key.text === '2023' || key.text === 'Problemsovling ' || key.text === 'Algorithm'){
          return navigate(`/tag`)
        }
        else navigate(`/tag/${key.text}`)
      }
    };
  }, [navigate]);
  const options = useMemo(() => {
    if (window.matchMedia("(max-width:768px)").matches)
      return {
        enableTooltip: false,
        deterministic: false,
        //fontFamily: "Nanum Gothic","헤드라인",
        fontFamily: "Nanum Gothic",
        fontSizes: [20, 80],
        fontStyle: "normal",
        fontWeight: "900",
        padding: 2,
        rotations: 2,
        rotationAngles: [0, 90],
        scale: "log",
        spiral: "rectangular",
      };
    return {
      enableTooltip: false,
      deterministic: false,
      //fontFamily: "Nanum Gothic","헤드라인",
      fontFamily: "Nanum Gothic",
      fontSizes: [50, 150],
      fontStyle: "normal",
      fontWeight: "900",
      padding: 2,
      rotations: 1,
      rotationAngles: [0, 0],
      scale: "log",
      spiral: "rectangular",
    };
  }, []);
  // var w=window.innerWidth, h=window.innerHeight;
  const size = useMemo(() => {
    if (window.matchMedia("(max-width:768px)").matches)
      return [window.innerWidth * 0.7, window.innerHeight * 0.6];
    //return [900, 650];  // 사이즈 조정
    return [810, 585];
  }, []);
  const words = useMemo(() => {
    // 2023, SKKU, Problemsolving, SKKU 키워드의 value 값은 동일하게 하여 부각시킴
    // 나머지 키워드들은 10을 기본 값으로 하고 5~15내외로 수정하여 작성
    return [
      {
        text: "#편의&도구",
        value: 16
      },
      {
        text: "#취업",
        value: 10
      },
      {
        text: "#기타",
        value: 10
      },
      {
        text: "#게임",
        value: 10
      },
      {
        text: "#패션",
        value: 10
      },
      {
        text: "#환경",
        value: 10
      },
      {
        text: "#의료",
        value: 10
      },
      {
        text: "#음악",
        value: 10
      },
      {
        text: "#운동&스포츠",
        value: 10
      },
      {
        text: "#요리",
        value: 10
      },
      {
        text: "#교육",
        value: 14
      },
      {
        text: "#영화&도서",
        value: 10
      },
      {
        text: "#생활",
        value: 14
      },
      {
        text: "#AI",
        value: 10
      },
      {
        text: "#여행",
        value: 10
      },
      {
        text: "#힐링",
        value: 10
      },
      {
        text: "#비즈니스",
        value: 10
      },
      {
        text: "#커뮤니케이션",
        value: 10
      },
      {
        text: "#쇼핑",
        value: 10
      },
      {
        text: "#지도",
        value: 10
      },
      {
        text: "#창작",
        value: 10
      },
      {
        text: "#컴퓨팅",
        value: 10
      },
      {
        text: "#보안",
        value: 10
      },
      /*
      {
        text: "#드라마",
        value: 10
      },
      {
        text: "#예술&디자인",
        value: 10
      },
      */
      {
        text: "LearningFair",
        value: 50
      },
      {
        text: "SKKU",
        value: 50
      },
      {
        text: "Problemsolving",
        value: 50
      },
      {
        text: "Algorithm",
        value: 50
      },
      {
        text: "2023",
        value: 50
      }
    ];
  }, []);
  return (
     <div className="WordCloud Font">
      <ReactWordcloud
        className="WordCloud Font"
        callbacks={callbacks}
        options={options}
        size={size}
        words={words}
      />
    </div>
  );
}

export default WordCloud;
