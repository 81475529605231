import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Layout from "./layouts/layout";
import Footer from "./layouts/footer";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/*" element={<Layout />} />
        </Routes>
      </Router>
    <Footer />      {/* jmy-add : 1024 21:38 */}
    </div>
  );
}

export default App;
