import "../css/Awards.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect} from "react";

function Awards() {
  const sessionCheckJson = {
    token: sessionStorage.getItem("login-token"),
    name: sessionStorage.getItem("login-name")
  };
  const navigate = useNavigate();

  async function session_check_api(sessionChkreqJson) {
    try {
      const response = await axios.post(
        "/api/session-check",
        JSON.stringify(sessionChkreqJson),
        {
          headers: {
            "Content-Type": `application/json`
          }
        }
      );

      if (response["data"]["session"] === "deactive") {
        console.log("You need to login in!");
        navigate("/");
      }

      
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    session_check_api(sessionCheckJson);
  }, []);

  //-----------세션 체크 완료------------------
  // 시상식 날짜 수정 jmy modify - 1016-2118
  // 시상식 결과 공개를 위한 페이지 수정 jmy - 1026-1519
  return (
      //수상 결과
      <div className="AwardsFinal">
        <div className="AwardsTitle">
          <h2>2023 SKKU Learning Fair 수상 결과</h2>
          <p> 여러분의 작품은 우리에게 새로운 아이디어와 비전을 제시합니다. <br/> 경쟁은 더 나은 자신을 찾고 더 나은 작품을 만드는 계기가 될 것입니다.<br/>
              이러한 열정과 노력을 계속 이어 나가길 희망하며, 박수와 응원을 보냅니다.<br/>
              </p>
          <p> 치열한 경쟁의 결과 아래와 같이 수상작이 선정 되었습니다. </p>
          <p> 축하합니다! </p>

          <h3>시상식 안내</h3>
          <p> 12월 7일(목) 오후 4시 30분 경영관 33B101호에서 진행될 예정입니다. <br/>
          </p>
          <img src={`${process.env.PUBLIC_URL}/awards.png`} />
        </div>
      </div>
      //수상 결과
/*
      <div className="Awards">
      <div className="AwardsWrapper">
        <h1 data-heading="12.5 11:00~ Coming Soon">
          <span>12.5</span>
          <br />
          11:00~
          <br/>
          Coming Soon
        </h1>
      </div>
      </div>

 */
  );
}

export default Awards;
